import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useMemo } from 'react'
import { PRIMARY_COLOR_MAPPING, SECONDARY_COLOR_MAPPING } from '../constants'
import { fontFamily } from '@mui/system'

// Styled wrapper with enhanced styles
const HarborThemeWrapper = styled('div')(
  ({ linkColor, ctaPrimaryLinkColor, ctaSecondaryLinkColor }) => ({
    '& .withHarborThemeCta': {
      backgroundColor: ctaPrimaryLinkColor,
      color: ctaSecondaryLinkColor,
    },
    '& .withHarborThemeWrapper a': {
      color: linkColor,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
      '&:focus': {
        outline: '2px solid blue',
        outlineOffset: '2px',
      },
    },
    '& .withHarborThemeWrapper': {
      display: 'flex',
      flexWrap: 'wrap',
    },
    '& .withHarborThemeWrapper img': {
      maxWidth: '100%',
    },    
    '& .withHarborThemeWrapper p:not(:empty), & .withHarborThemeWrapper ul, & .withHarborThemeWrapper ol,' : {
      width: '100%',
      fontFamily: 'Roboto',
      marginBottom: '8px',
    },
    '& .withHarborThemeWrapper span': {
      fontFamily: 'Roboto',
    },
    '& .withHarborThemeWrapper p:empty': {
      margin: 0,
      padding: 0,
    },
    '& .withHarborThemeWrapper h1, & .withHarborThemeWrapper h2, & .withHarborThemeWrapper h3, & .withHarborThemeWrapper h4, & .withHarborThemeWrapper h5, & .withHarborThemeWrapper h6': {
      width: '100%',
      fontFamily: 'Poppins',
      margin: '8px 0',
    },
    '& .withHarborThemeWrapper h1[class*="paint"], \
     & .withHarborThemeWrapper h2[class*="paint"], \
     & .withHarborThemeWrapper h3[class*="paint"], \
     & .withHarborThemeWrapper h4[class*="paint"], \
     & .withHarborThemeWrapper h5[class*="paint"], \
     & .withHarborThemeWrapper h6[class*="paint"]': {
      width: 'unset !important',
      display: 'inline !important',
    },
    '& .withHarborThemeWrapper hr': {
      width: '100%',
      height: '1px',
      color: 'rgb(9, 42, 72)',
    },
  })
)

export const HarborCsThemeProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      contentstackTheme {
        default_link_color {
          link_color
        }
        default_cta_color {
          cta_color
        }
      }
    }
  `)

  const theme = useMemo(
    () => ({
      linkColor:
        PRIMARY_COLOR_MAPPING[data.contentstackTheme?.default_link_color?.link_color] ||
        PRIMARY_COLOR_MAPPING.defaultLinkColor,
      ctaPrimaryLinkColor:
        PRIMARY_COLOR_MAPPING[data.contentstackTheme?.default_cta_color?.cta_color] ||
        PRIMARY_COLOR_MAPPING.defaultCtaPrimaryColor,
      ctaSecondaryLinkColor:
        SECONDARY_COLOR_MAPPING[data.contentstackTheme?.default_cta_color?.cta_color] ||
        SECONDARY_COLOR_MAPPING.defaultCtaSecondaryColor,
    }),
    [data]
  )

  if (!data?.contentstackTheme) {
    console.warn('HarborCsThemeProvider query returned no data.')
    return <>{children}</> // Fallback rendering
  }  

  return (
    <HarborThemeWrapper
      linkColor={theme.linkColor}
      ctaPrimaryLinkColor={theme.ctaPrimaryLinkColor}
      ctaSecondaryLinkColor={theme.ctaSecondaryLinkColor}
    >
      {children}
    </HarborThemeWrapper>
  )
}

HarborCsThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default HarborCsThemeProvider