import { createTheme } from '@mui/material/styles'

// Import colors
import {
  BLUE_BASE,
  GRAY_SCALE_0,
  GOLDEN_BASE,
  WHITE,
  ERROR_TEXT_DARK,
  ERROR_MAIN,
  ERROR_LIGHT,
  ERROR_DARK,
  ERROR_LIGHT_BG,
  PRIMARY,
  WARNING_TEXT_DARK,
  WARNING_MAIN,
  WARNING_DARK,
  WARNING_LIGHT,
  WARNING_LIGHT_BG,
  TEXT_DARK,
  MAIN,
  DARK,
  LIGHT,
  LIGHT_BG,
  SUCCESS_MAIN,
  SUCCESS_DARK,
  SUCCESS_LIGHT,
  SUCCESS_LIGHT_BG,
  GREY_A100,
  GREY_A200,
  GREY_A700,
  KNOB_INACTIVEKNOB,
  GRAY_SCALE_1,
  GRAY_SCALE_2,
  GRAY_SCALE_3,
  GRAY_SCALE_4,
  GRAY_SCALE_5,
  GRAY_SCALE_6,
  GRAY_SCALE_7,
  GRAY_SCALE_8,
  GRAY_SCALE_9,
  DISABLED_HINT,
  HOVER,
  SELECTED,
  DISABLED_BG_FOCUS,
  DISABLED,
  BLUE_LIGHT,
  BORDER,
  BACKDROP,
  SNACKBAR,
  ACTIVE_RATING,
  ACTIVE_LOVING,
} from './variables/colors'

// Import the simplified typography
import typography from './variables/typography'

const {
  fontFamily,
  fontWeights,
  fontSizes,
  lineHeights,
  letterSpacing,
  paragraphSpacing,
} = typography

/***********************************************
 * Create MUI Theme
 ***********************************************/
const theme = createTheme({
  cssVariables: true,
  /***********************************************
   * Palette
   ***********************************************/
  palette: {
    // Use color='primary' for your main brand color (BLUE_BASE)
    primary: {
      hue: '209',
      background: 'hsl(209, 78%, 16%, 1)',
      base: BLUE_BASE,
      contrast_text: GRAY_SCALE_0,
      text_dark: 'hsl(209, 78%, 16%, 1)',
      text_light: '#BBD6EF',
      main: 'hsl(209, 57%, 34%)',
      light: 'hsla(200, 31%, 94%, 1)',
      dark: 'hsl(209, 81%, 14%)',
      darkBackground: 'hsla(209, 90%, 54%, 0.08)',
      lightBackground60: 'rgba(9, 42, 72, 0.6)',
      selected: 'hsla(209, 90%, 54%, 0.08)',
    },

    // Use color='secondary' for GOLD
    secondary: {
      hue: '43',
      base: GOLDEN_BASE,
      contrast_text: WHITE,
      text_dark: 'hsl(43, 90%, 43%)',
      text_light: '#D8B45ACC',
      text_light30: '#D8B45A4D',
      main: '#D8B45A',
      light: 'hsl(43, 89%, 82%)',
      dark: 'hsl(43, 92%, 25%)',
      background: 'hsla(43, 90%, 54%, 0.08)',
      selected: 'hsla(43, 90%, 54%, 0.08)',
      border: 'hsla(43, 90%, 54%, 0.5)',
    },

    error: {
      contrast_text: WHITE,
      text_dark: ERROR_TEXT_DARK,
      main: ERROR_MAIN,
      light: ERROR_LIGHT,
      dark: ERROR_DARK,
      light_bg: ERROR_LIGHT_BG,
    },

    warning: {
      contrast_text: PRIMARY,
      text_dark: WARNING_TEXT_DARK,
      main: WARNING_MAIN,
      dark: WARNING_DARK,
      light: WARNING_LIGHT,
      light_bg: WARNING_LIGHT_BG,
    },

    info: {
      contrast_text: WHITE,
      text_dark: TEXT_DARK,
      main: MAIN,
      dark: DARK,
      light: LIGHT,
      light_bg: LIGHT_BG,
    },

    success: {
      contrast_text: WHITE,
      text_dark: TEXT_DARK,
      main: SUCCESS_MAIN,
      dark: SUCCESS_DARK,
      light: SUCCESS_LIGHT,
      light_bg: SUCCESS_LIGHT_BG,
    },

    grey: {
      grey_50: KNOB_INACTIVEKNOB,
      grey_100: GRAY_SCALE_1,
      grey_200: GRAY_SCALE_2,
      grey_300: GRAY_SCALE_3,
      grey_400: GRAY_SCALE_4,
      grey_500: GRAY_SCALE_5,
      grey_600: GRAY_SCALE_6,
      grey_700: GRAY_SCALE_7,
      grey_800: GRAY_SCALE_8,
      grey_900: GRAY_SCALE_9,
      black: DARK,
      white: GRAY_SCALE_0,
      grey_A100: GREY_A100,
      grey_A200: GREY_A200,
      grey_A400: GRAY_SCALE_7,
      grey_A700: GREY_A700,
    },

    text: {
      primary: BLUE_BASE,    // Typically your brand color for "primary" text
      secondary: WHITE,      // White text
      disabled: DISABLED_HINT,
      hint: DISABLED_HINT,
    },

    action: {
      main: GRAY_SCALE_3,
      hover: HOVER,
      selected: SELECTED,
      disabled_bg: DISABLED_BG_FOCUS,
      disabled: DISABLED,
      focus: DISABLED_BG_FOCUS,
    },

    background: {
      paper: GRAY_SCALE_0,
      surface: GRAY_SCALE_1,
      alternative: BLUE_LIGHT,
      default: WHITE,
    },

    other: {
      border: BORDER,
      backdrop: BACKDROP,
      snackbar: SNACKBAR,
      active_rating: ACTIVE_RATING,
      active_loving: ACTIVE_LOVING,
      divider: DISABLED_BG_FOCUS,
      knob_inactive: KNOB_INACTIVEKNOB,
    },

    // For quick references
    dark: DARK,
    light: GRAY_SCALE_0,
  },

  /***********************************************
   * Typography
   ***********************************************/
  typography: {
    // Global default font (applies to body)
    fontFamily: fontFamily.body,

    // Example headings with consistent scale
    h1: {
      fontFamily: fontFamily.heading,
      fontWeight: fontWeights.headingBold,
      fontSize: fontSizes['2xl'],      // 32px
      lineHeight: lineHeights.snug,    // 1.3
      '@media (min-width:600px)': {
        fontSize: fontSizes['3xl'],    // 40px at >=600px
      },
    },
    h2: {
      fontFamily: fontFamily.heading,
      fontWeight: fontWeights.headingMedium,
      fontSize: fontSizes.xl,          // 24px
      lineHeight: lineHeights.snug,
      '@media (min-width:600px)': {
        fontSize: fontSizes['2xl'],    // 32px
      },
    },
    h3: {
      fontFamily: fontFamily.heading,
      fontWeight: fontWeights.headingMedium,
      fontSize: fontSizes.lg,          // 20px
      lineHeight: lineHeights.normal,  // 1.5
      '@media (min-width:600px)': {
        fontSize: fontSizes.xl,        // 24px
      },
    },
    h4: {
      fontFamily: fontFamily.heading,
      fontWeight: fontWeights.headingRegular,
      fontSize: fontSizes.md,          // 16px
      lineHeight: lineHeights.normal,
      '@media (min-width:600px)': {
        fontSize: fontSizes.lg,        // 20px
      },
    },
    h5: {
      fontFamily: fontFamily.heading,
      fontWeight: fontWeights.headingRegular,
      fontSize: fontSizes.sm,          // 14px
      lineHeight: lineHeights.normal,
      '@media (min-width:600px)': {
        fontSize: fontSizes.md,        // 16px
      },
    },
    h6: {
      fontFamily: fontFamily.heading,
      fontWeight: fontWeights.headingRegular,
      fontSize: fontSizes.sm,          // 14px
      lineHeight: lineHeights.snug,    // 1.3
    },

    // Body
    body1: {
      fontFamily: fontFamily.body,
      fontWeight: fontWeights.bodyRegular,
      fontSize: fontSizes.md,           // 16px
      lineHeight: lineHeights.normal,   // 1.5
    },
    body2: {
      fontFamily: fontFamily.body,
      fontWeight: fontWeights.bodyRegular,
      fontSize: fontSizes.sm,           // 14px
      lineHeight: lineHeights.normal,
    },

    // Subtitles
    subtitle1: {
      fontFamily: fontFamily.heading,
      fontWeight: fontWeights.headingRegular,
      fontSize: fontSizes.md,           // 16px
      lineHeight: lineHeights.snug,
    },
    subtitle2: {
      fontFamily: fontFamily.heading,
      fontWeight: fontWeights.headingRegular,
      fontSize: fontSizes.sm,           // 14px
      lineHeight: lineHeights.snug,
    },

    // Captions
    caption: {
      fontFamily: fontFamily.body,
      fontWeight: fontWeights.bodyRegular,
      fontSize: fontSizes.xs,           // 12px
      lineHeight: lineHeights.normal,
    },
    overline: {
      fontFamily: fontFamily.body,
      fontWeight: fontWeights.bodyBold,
      fontSize: fontSizes.xs,
      letterSpacing: letterSpacing.wider, // ~1
      textTransform: 'uppercase',
    },

    // Buttons
    button: {
      fontFamily: fontFamily.label,
      fontWeight: fontWeights.buttonSemiBold,
      fontSize: fontSizes.sm,           // 14px
      lineHeight: lineHeights.snug,
      textTransform: 'capitalize',
    },
    buttonLg: {
      fontFamily: fontFamily.label,
      fontWeight: fontWeights.buttonSemiBold,
      fontSize: fontSizes.md,           // 16px
      lineHeight: lineHeights.snug,
      textTransform: 'capitalize',
    },
    buttonSm: {
      fontFamily: fontFamily.label,
      fontWeight: fontWeights.buttonSemiBold,
      fontSize: fontSizes.xs,           // 12px
      lineHeight: lineHeights.snug,
      textTransform: 'capitalize',
    },

    // Code
    code: {
      fontFamily: fontFamily.code,
      fontWeight: fontWeights.codeRegular,
      fontSize: fontSizes.sm,           // 14px
      lineHeight: lineHeights.normal,
    },

    // Icons
    icon3xlarge: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconDefault,
      fontSize: fontSizes.icon3xl,      // 360px
      lineHeight: lineHeights.icon3xl,  // '360px'
    },
    icon2xlarge: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconDefault,
      fontSize: fontSizes.icon2xl,      // 180px
      lineHeight: lineHeights.icon2xl,
    },
    iconXlarge: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconDefault,
      fontSize: fontSizes.iconXl,       // 48px
      lineHeight: lineHeights.iconXl,
    },
    iconXlargeSolid: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconSolid,
      fontSize: fontSizes.iconXl,
      lineHeight: lineHeights.iconXl,
    },
    iconLarge: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconDefault,
      fontSize: fontSizes.iconLg,       // 32px
      lineHeight: lineHeights.iconLg,
    },
    iconLargeSolid: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconSolid,
      fontSize: fontSizes.iconLg,
      lineHeight: lineHeights.iconLg,
    },
    iconMedium: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconDefault,
      fontSize: fontSizes.iconMd,       // 24px
      lineHeight: lineHeights.iconMd,
    },
    iconMediumSolid: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconSolid,
      fontSize: fontSizes.iconMd,
      lineHeight: lineHeights.iconMd,
    },
    iconSmall: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconDefault,
      fontSize: fontSizes.iconSm,       // 16px
      lineHeight: lineHeights.iconSm,
    },
    iconSmallSolid: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconSolid,
      fontSize: fontSizes.iconSm,
      lineHeight: lineHeights.iconSm,
    },
    iconXsmall: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconDefault,
      fontSize: fontSizes.iconXs,       // 12px
      lineHeight: lineHeights.iconXs,
    },
    iconXsmallSolid: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconSolid,
      fontSize: fontSizes.iconXs,
      lineHeight: lineHeights.iconXs,
    },
  },

  /***********************************************
   *  Breakpoints
   ***********************************************
   * If you want custom breakpoints, you can define them here.
   * Otherwise, MUI defaults to: xs:0, sm:600, md:960, lg:1280, xl:1920
   ***********************************************/
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },

  /***********************************************
   * (Optional) Spacing
   ***********************************************
   * MUI uses an 8px spacing scale by default, but
   * you can override if you prefer a different scale.
   ***********************************************/
  spacing: 8,
})

export default theme