import PropTypes from 'prop-types'
import React, { useRef, useEffect, useCallback } from 'react'
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha'


const CustomRecaptcha = React.memo(({
  gatsbyRecaptchaKey = '',
  onRecaptchaChange,
  onRecaptchaExpire,
  formSubmitted,
}) => {
  const recaptchaRef = useRef(null)
  useEffect(() => {
    if (formSubmitted && recaptchaRef.current) {
      recaptchaRef.current.reset()
      onRecaptchaChange('')
    }
  }, [formSubmitted, onRecaptchaChange])

  const handleRecaptchaChange = useCallback((response) => {
    onRecaptchaChange(response)
  }, [onRecaptchaChange])

  const handleRecaptchaExpire = useCallback(() => {
    onRecaptchaExpire()
  }, [onRecaptchaExpire])

  return (
    <>
      {gatsbyRecaptchaKey && (
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={gatsbyRecaptchaKey}
          size='normal'
          isolated={true}
          onChange={handleRecaptchaChange}
          onExpired={handleRecaptchaExpire}
        />
      )}
    </>
  )
})

CustomRecaptcha.displayName = 'CustomRecaptcha'

CustomRecaptcha.propTypes = {
  gatsbyRecaptchaKey: PropTypes.string,
  onRecaptchaChange: PropTypes.func,
  onRecaptchaExpire: PropTypes.func,
  formSubmitted: PropTypes.bool,
}

export default CustomRecaptcha
